import React, { useEffect } from 'react';
import ReactGA from 'react-ga4'
import { getUser, getPermissions} from 'ducks/auth/selectors';
import { useReduxSelector } from 'hooks';

const withTracker = (WrappedComponent, pathOverride = null, options = {}) => {
  const trackPage = (page, options) => {

    //console.log('GA Options: ', options);
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.send({ hitType: "pageview", page: page})
  };

  const findRole = ( permissions ) => {
    if (permissions.find(str => str === 'global.view_admin_ui')) {
      return 'Administrator'
    };

    if (permissions.find(str => str === 'global.view_utility_ui')) {
      return 'Utility Manager'
    };

    if (permissions.find(str => str === 'global.view_division_ui')) {
      return 'Area Manager'
    };

    if (permissions.find(str => str === 'global.view_team_ui')) {
      return 'Team Supervisor'
    };

    if (permissions.find(str => str === 'global.view_field_tech_ui')) {
      return 'Field Technician'
    };

  };
  const HOC = props => {
    const user = useReduxSelector(getUser);
    const perms = useReduxSelector(getPermissions);
    //console.log('permissions: ', perms);

    useEffect(() => {
      if (user) {
        let role = findRole(perms);
        //console.log('permissions 2: ', perms);
        //console.log('role: ', role);

        const { ac_id = '', firstname = '', lastname = '', email = '', phone = '', org = ''} = user;
        options = {
          user_properties: {
            user_acid:  ac_id, 
            user_name: firstname + ' ' + lastname,
            user_email: email,
            user_phone: phone, 
            user_org: org,
            user_role: role
          }
        }  
      }
    }, [user, perms]);

    useEffect(() => trackPage(pathOverride || props.location.pathname, options), [
      props.location.pathname
    ]);
  
    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracker;
